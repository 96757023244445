import React from 'react';
import { html2react } from 'helpers/parser';

interface CareersIntroSectionProps {
  title: string;
  box1Title: string;
  box1Body: string;
  box2Title: string;
  box2Body: string;
  image1Url: string;
  image2Url: string;
  femaleLeadershipPercentage: string;
  skupeeps: string;
}

const CareersIntroSection: React.FC<CareersIntroSectionProps> = ({
  title,
  box1Title,
  box1Body,
  box2Title,
  box2Body,
  image1Url,
  image2Url,
  femaleLeadershipPercentage,
  skupeeps,
}) => {
  return (
    <div className="u-section u-container px-6 lg:px-md xl:px-0">
      <h2 className="text-center u-h2 lg:px-40 mb-16 lg:mb-36">
        {html2react(title)}
      </h2>

      <div className="flex flex-col-reverse lg:flex-row items-center">
        <div className="w-full lg:w-6/12 lg:px-16 py-16 md:py-8">
          <h3 className="mb-3.5 font-bold u-h2">{box1Title}</h3>
          <p className="text-xl text-paragraph tracking-tighter">{box1Body}</p>
        </div>
        <div className="w-full lg:w-6/12">
          <img src={image1Url} alt={box1Title} className="w-full h-auto" />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-6/12">
          <img src={image2Url} alt={box2Title} className="w-full h-auto" />
        </div>
        <div className="w-full lg:w-6/12 lg:px-16 py-16 md:py-8">
          <h3 className="mb-3.5 font-bold u-h2">{box2Title}</h3>
          <p className="mb-7 text-xl text-paragraph tracking-tighter">
            {box2Body}
          </p>
          <div className="text-berry font-semibold text-3xl lg:text-5xl">
            {femaleLeadershipPercentage}
          </div>
          <div className="text-lg lg:text-xl font-semibold leading-loose">
            Female leadership
          </div>
        </div>
      </div>

      <div className="text-center mt-16 md:mt-32 lg:mt-44">
        <div className="text-aqua font-semibold tracking-tightest text-5xl md:text-200">
          {skupeeps}
        </div>
        <div className="text-2xl md:text-4xl font-bold">Skupeeps</div>
      </div>
    </div>
  );
};

export default CareersIntroSection;
