import React from 'react';
import CareerBenefit from 'components/molecules/CareerBenefit';

interface CareerBenefit {
  title: string;
  body: string;
  imageUrl: string;
}

interface CareerBenefitsSectionProps {
  benefits: CareerBenefit[];
}
const CareerBenefitsSection: React.FC<CareerBenefitsSectionProps> = ({
  benefits,
}) => {
  return (
    <div className="u-section bg-tile">
      <div className="u-container flex flex-col md:flex-row flex-wrap items-center lg:items-start py-16 lg:py-44">
        {benefits.map((benefit, index) => (
          <CareerBenefit
            key={index}
            image={benefit.imageUrl}
            title={benefit.title}
            body={benefit.body}
          />
        ))}
      </div>
    </div>
  );
};

export default CareerBenefitsSection;
