import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import CompanyPerk from 'components/molecules/CompanyPerk';
import AwardsSection from 'components/organisms/AwardsSection';
import CareerBenefitsSection from 'components/organisms/CareerBenefitsSection';
import CareerLocationsSection from 'components/organisms/CareerLocationsSection';
import CareersHeroSection from 'components/organisms/CareersHeroSection';
import CareersIntroSection from 'components/organisms/CareersIntroSection';
import CompanyPerksSection from 'components/organisms/CompanyPerksSection';
import ContactLinksSection from 'components/organisms/ContactLinksSection';
import CtaSection from 'components/organisms/CtaSection';
import OpenPositionsSection from 'components/organisms/OpenPositionsSection';
import PressReleaseGridSection from 'components/organisms/PressReleaseGridSection';
import TilesSection from 'components/organisms/TilesSection';

const CareersPageContent = ({
  hero,
  introSection,
  companyPerksSection,
  location1,
  location2,
  inlineImage,
  awardsSection,
  companyBenefits,
  kpIsSectionTitle,
  kpis,
  opportunitiesSectionTitle,
  ctaSection,
  allGreenhouseJobPost,
}) => {
  const locationMapping = {
    'Denver, Colorado': 'denver',
    'San Francisco, California': 'sf',
  };

  return (
    <>
      <CareersHeroSection
        title={hero?.title}
        cta={hero?.primaryCta}
        imageUrl={hero?.image}
      />
      <CareersIntroSection
        title={introSection?.title}
        box1Title={introSection?.box1Title}
        box1Body={introSection?.box1Body}
        box2Title={introSection?.box2Title}
        box2Body={introSection?.box2Body}
        image1Url={introSection?.image1}
        image2Url={introSection?.image2}
        femaleLeadershipPercentage={introSection?.femaleLeadershipPercentage}
        skupeeps={introSection?.skupeeps}
      />
      <CompanyPerksSection title={companyPerksSection?.title} grey>
        {companyPerksSection?.features.map((feature, index) => (
          <CompanyPerk
            key={index}
            title={feature?.title}
            body={feature?.body}
            image={feature?.image}
            imageSide={index % 2 === 1 ? 'left' : 'right'}
          />
        ))}
      </CompanyPerksSection>
      <CareerLocationsSection location1={location1} location2={location2} />
      <img src={inlineImage} alt="Careers" className="w-full h-auto" />
      <AwardsSection
        title={awardsSection?.title}
        awards={awardsSection?.awards.map((award) => ({
          title: award?.title,
          year: award?.year,
          imageUrl: award?.image,
        }))}
      />
      <CareerBenefitsSection
        benefits={companyBenefits.map((benefit) => ({
          title: benefit?.title,
          body: benefit?.body,
          imageUrl: benefit?.image,
        }))}
      />
      <TilesSection title={kpIsSectionTitle} cols={4} tiles={kpis} />
      <OpenPositionsSection
        title={opportunitiesSectionTitle}
        positions={allGreenhouseJobPost.map(({ node: opportunity }) => ({
          location:
            opportunity?.location?.name in locationMapping
              ? locationMapping[opportunity?.location?.name]
              : 'remote',
          title: opportunity?.title,
          url: `https://boards.greenhouse.io/skupos/jobs/${opportunity?.greenhouseId}`,
        }))}
      />
      {/*
    <PressReleaseGridSection
      title="What we’ve been up to"
      items={[
        {
          category: 'Press Release',
          url: '/',
          title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
          image: '/images/press-release-1.png',
        },
        {
          category: 'Press Release',
          url: '/',
          title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
          image: '/images/press-release-1.png',
        },
        {
          category: 'Press Release',
          url: '/',
          title: 'Lectus sed cursus eu adipiscing congue. Egestas et.',
          image: '/images/press-release-1.png',
        },
      ]}
    />
    */}
      <ContactLinksSection
        links={[
          { to: '/about', label: 'Our Story' },
          {
            to: '/contact',
            label: 'Contact Us',
            backgroundPosition: 'center right 52px',
          },
        ]}
      />
      <CtaSection headline={ctaSection?.heading} />
    </>
  );
};

const GREENHOUSEJOBS_FIXTURE = {
  edges: [
    {
      node: {
        greenhouseId: '4302693003',
        location: { name: 'Denver, CO or San Francisco, CA' },
        title: 'Senior Software Engineer (Backend) - SF/DEN',
      },
    },
    {
      node: {
        greenhouseId: '4257883003',
        location: { name: 'Denver, Colorado' },
        title: 'Enterprise Client Manager',
      },
    },
    {
      node: {
        greenhouseId: '4257946003',
        location: { name: 'Denver, Colorado' },
        title: 'Salesforce Developer',
      },
    },
  ],
};

export const CareersPreview = ({ entry }) => {
  return (
    <CareersPageContent
      {...entry.get('data').toJS()}
      allGreenhouseJobPost={GREENHOUSEJOBS_FIXTURE.edges}
    />
  );
};

const CareersPage = ({
  data: {
    markdownRemark: { frontmatter },
    allGreenhouseJobPost,
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <CareersPageContent
        {...frontmatter}
        allGreenhouseJobPost={allGreenhouseJobPost?.edges}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CareersPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/careers/" }) {
      frontmatter {
        hero {
          title
          primaryCta {
            label
            url
          }
          image
        }
        introSection {
          title
          box1Title
          box1Body
          box2Title
          box2Body
          image1
          image2
          femaleLeadershipPercentage
          skupeeps
        }
        companyPerksSection {
          title
          features {
            title
            body
            image
          }
        }
        location1
        location2
        inlineImage
        awardsSection {
          title
          awards {
            title
            year
            image
          }
        }
        companyBenefits {
          title
          body
          image
        }
        kpIsSectionTitle
        kpis {
          label
          value
          color
        }
        opportunitiesSectionTitle
        ctaSection {
          heading
        }
        seo {
          title
          description
          openGraphImage
        }
      }
    }
    allGreenhouseJobPost(filter: { external: { eq: true } }) {
      edges {
        node {
          title
          location {
            name
          }
          greenhouseId
        }
      }
    }
  }
`;

export default CareersPage;
