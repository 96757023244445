import { Link } from 'gatsby';
import React, { useState } from 'react';
import { html2react } from 'helpers/parser';

interface OpenPosition {
  location: 'sf' | 'denver' | 'remote';
  title: string;
  url: string;
}

interface OpenPositionsSectionProps {
  title: string;
  positions: OpenPosition[];
}

const OpenPositionsSection: React.FC<OpenPositionsSectionProps> = ({
  title,
  positions,
}) => {
  const [locationFilter, setLocationFilter] = useState<
    'sf' | 'denver' | 'remote'
  >('sf');
  const [isExpanded, setExpanded] = useState(false);

  const locations = {
    sf: 'San Francisco, California',
    denver: 'Denver, Colorado',
    remote: 'Remote',
  };

  const handleFilterChanged = (filter: 'sf' | 'denver' | 'remote') => {
    setLocationFilter(filter);
    setExpanded(false);
  };

  return (
    <div className="u-section u-container px-6 lg:px-md" id="positions">
      <div className="mb-20 text-center">
        <h2 className="mb-12 lg:mb-12 u-h2 mx-auto max-w-3xl">
          {html2react(title)}
        </h2>

        <Link to="/contact" className={`u-btn u-btn--outline`}>
          Get in touch
        </Link>
      </div>

      <div className="flex flex-col lg:flex-row justify-between">
        <div className="w-full lg:w-5/12 text-center md:space-y-16 flex justify-between md:block items-center">
          <div>
            <div className="text-aqua font-semibold text-5xl md:text-8xl">
              {positions.length}
            </div>
            <div className="font-semibold text-2xl lg:text-3xl">
              Opportunities
            </div>
          </div>

          <div>
            <div className="text-aqua font-semibold text-5xl md:text-8xl">
              2
            </div>
            <div className="font-semibold text-2xl lg:text-3xl">Locations</div>
          </div>
        </div>
        <div className="lg:w-6/12 md:mx-16 lg:mx-0 mt-16 lg:mt-0">
          <div className="flex space-x-2.5 mb-4">
            <button
              className={`flex-1 border py-4 px-1 leading-none h-auto rounded font-semibold text-center md:text-lg transition focus:ring-0 focus:outline-none ${
                locationFilter == 'sf'
                  ? 'bg-dark-grey text-white border-dark-grey'
                  : 'text-dark-grey border-border-grey hover:bg-tile '
              }`}
              onClick={() => handleFilterChanged('sf')}
            >
              San Francisco
            </button>
            <button
              className={`flex-1 border py-4 px-1 leading-none rounded font-semibold text-center md:text-lg transition focus:ring-0 focus:outline-none  ${
                locationFilter == 'denver'
                  ? 'bg-dark-grey text-white border-dark-grey'
                  : 'text-dark-grey border-border-grey hover:bg-tile '
              }`}
              onClick={() => handleFilterChanged('denver')}
            >
              Denver
            </button>
            <button
              className={`flex-1 border py-4 px-1 leading-none rounded font-semibold text-center md:text-lg transition focus:ring-0 focus:outline-none ${
                locationFilter == 'remote'
                  ? 'bg-dark-grey text-white border-dark-grey'
                  : 'text-dark-grey border-border-grey hover:bg-tile '
              }`}
              onClick={() => handleFilterChanged('remote')}
            >
              Remote
            </button>
          </div>
          <div>
            <div className="space-y-3">
              {positions.filter(
                (position) => position.location === locationFilter,
              ).length > 0 ? (
                positions
                  .filter((position) => position.location === locationFilter)
                  .slice(0, isExpanded ? undefined : 5)
                  .map((position, index) => (
                    <a
                      href={position.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="rounded flex bg-tile py-3 md:py-4 lg:py-7 px-4 md:px-7 lg:px-9 items-center justify-between transition-opacity duration-150 ease-in-out hover:opacity-75"
                      key={index}
                    >
                      <div>
                        <div className="font-bold text-lg md:text-2xl tracking-tighter">
                          {position.title}
                        </div>
                        <div className="opacity-60 md:text-lg">
                          {locations[position.location]}
                        </div>
                      </div>
                      <svg
                        className="ml-2 w-auto h-2.5 md:h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="14"
                        fill="none"
                        viewBox="0 0 16 14"
                      >
                        <path
                          fill="#0A2533"
                          d="M11.42 6.007H.84v2.465h10.58l-3.697 3.697 1.743 1.742 5.8-5.8a1.232 1.232 0 000-1.743l-5.8-5.8-1.743 1.743 3.697 3.696z"
                        ></path>
                      </svg>
                    </a>
                  ))
              ) : (
                <div className="text-dark-grey">
                  No opportunities available in this location.
                </div>
              )}
            </div>
            {!isExpanded &&
              positions.filter(
                (position) => position.location === locationFilter,
              ).length > 5 && (
                <button
                  className="mt-12 u-btn u-btn--outline"
                  onClick={() => setExpanded(true)}
                >
                  See more
                </button>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenPositionsSection;
