import React from 'react';

interface CareerLocationsSectionProps {
  location1: string;
  location2: string;
}

const CareerLocationsSection: React.FC<CareerLocationsSectionProps> = ({
  location1,
  location2,
}) => {
  return (
    <div className="my-20 md:my-56 u-container text-center">
      <div className="text-6xl md:text-100 lg:text-200 font-semibold tracking-tightest">
        {location1} <span className="text-yellow">+</span> {location2}
      </div>
    </div>
  );
};

export default CareerLocationsSection;
