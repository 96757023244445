import { Link } from 'gatsby';
import React from 'react';

interface CareersHeroSectionProps {
  title: string;
  cta: {
    label: string;
    url: string;
  };
  imageUrl: string;
}

const CareersHeroSection: React.FC<CareersHeroSectionProps> = ({
  title,
  cta,
  imageUrl,
}) => {
  return (
    <div className="pt-16 md:pt-xl mb-20 md:mb-32">
      <div className="py-28 px-6 lg:px-md xl:px-44 u-container text-center ">
        <h1 className="u-h1 mb-12">{title}</h1>
        <Link to={cta.url} className="u-btn u-btn--green">
          {cta.label}
        </Link>
      </div>
      <img src={imageUrl} alt="Careers" className="w-full h-auto" />
    </div>
  );
};

export default CareersHeroSection;
