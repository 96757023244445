import React from 'react';

interface CareerBenefitProps {
  image: string;
  title: string;
  body: string;
}

const CareerBenefit: React.FC<CareerBenefitProps> = ({
  image,
  title,
  body,
}) => {
  return (
    <div className="w-full md:w-6/12 lg:w-3/12 flex flex-col items-center px-6 text-center mb-8 md:mb-16 lg:mb-0">
      <img src={image} alt={title} className="w-44 h-auto mb-9" />
      <h3 className="mb-2.5 font-bold text-2.5xl tracking-tightest">{title}</h3>
      <p className="text-lg leading-7 px-4">{body}</p>
    </div>
  );
};

export default CareerBenefit;
